import {
    getAutoAllocateRequestsPageRoute,
    getBankDepositRoute,
    getBankTransactionsHistoryRoute,
    getCRMInOpsRoute,
    getCallsOverviewRoute,
    getCarrierReconciliationRoute,
    getCarrierTransactionsHistoryRoute,
    getCashRemittanceRoute,
    getConsignmentUploadRequestRoute,
    getCustomTripRequestsRoute,
    getDevToolsRoute,
    getDomesticCustomerActivityRoute,
    getDomesticTrackRoute,
    getDownloadsRoute,
    getFoodDashboardAnalytics,
    getFoodDashboardCODCashSettlement,
    getFoodDashboardDispatchScreen,
    getFoodDashboardNotifications,
    getFoodDashboardOrderManagement,
    getFoodDashboardReporting,
    getFoodDashboardRiderManagement,
    getFoodDashboardRosterManagement,
    getSupplementoryServicesRoute,
    getNetworkRoute,
    getGeocodingPlaygroundRoute,
    getIntegrationMarketplaceRoute,
    getInvoiceRoute,
    getLTLAnalyicsRoute,
    getMyCallsRoute,
    getPrintLabelRequestRoute,
    getRetailAnalyticsOverviewRoute,
    getRetailERPSyncRoute,
    getRetailStoreViewRoute,
    getRetailSupportPortalRoute,
    getRetailTrackingRoute,
    getRetailTripManagerRoute,
    getRiderReconciliationRoute,
    getRoutingPlaygroundRoute,
    getShelfBaseRoute,
    getSupplyRequestRoute,
    getTerritoryOptimizationRoute,
    getThirdPartyViewRoute,
    getVehicleRequestRoute,
    getTransactionsHistoryRoute,
    getLiveDashboardRoute,
    getGenericDownloadRequestsRoute,
} from 'src/routing/utils';
import { IntegrationMarketplaceIcon } from '../common/icons/IntegrationMarketplaceIcon';
import { AddressSetupIcon, AdminSetupIcon, AssetsIcon, CRMIcon, MiddleMileSetupIcon, StoreConfigurationIcon, UserIcon } from '../common/icons/NavbarMenuIcons';
import { setupSideNavConfig } from '../pages/OpsDashboard/Setup/setupSideNavConfig';
import { getTmsBagRoute, getTmsDownloadRequestRoute, getTmsISTRoute, getTmsOverviewRoute, getTmsVehicleAttendanceRoute, getTmsVehicleRoute } from '../pages/TMSDashboard/routes';
import { getManagePISRoute, getPISSummaryRoute } from '../pages/PayinSlip/routes';
import { setupRoutes } from '../pages/OpsDashboard/Setup/routes';
import { getOpsV1AttendanceRoute, getOpsV1CoordinatorPortalRoute, getOpsV1DeliveryTaskListRoute, getOpsV1KmReadingRoute, getOpsV1MediumListRoute, getOpsV1PickupTaskListRoute, getOpsV1TrackingRoute } from '../pages/OpsDashboardV1Pages/routes';
import { API_PLAYGROUND_BASE_PATH } from '../common/persistentIframeRenderer/constants';
import { getPriceCalculatorRoute } from '../pages/PriceCalculator/routes';
import { getVendorPayoutRoute } from '../pages/VendorPayout/routes';
import { getValletTransactionsRoute } from '../pages/ValletTransactions/routes';
import { useTranslation } from 'react-i18next';

export const SetupSubmenu = [
    {
        title: 'User',
        translationKey: 'user',
        icon: UserIcon,
        subitems: [
            'Employees',
            'App Users',
            'Rider Management',
            'Rider Payout',
            'Roster Management',
            'Rider Approval',
        ],
    },
    {
        title: 'Assets',
        translationKey: 'assets',
        icon: AssetsIcon,
        subitems: [
            'Hubs',
            'Vehicle',
            'Transport',
        ],
    },
    {
        title: 'Customer',
        translationKey: 'customer',
        icon: CRMIcon,
        subitems: [
            'Customer Management',
            'Customer Allocation Rules',
        ],
    },
    {
        title: 'Middle Mile',
        translationKey: 'middle_mile',
        icon: MiddleMileSetupIcon,
        subitems: [
            'Shelf Management',
            'Rate Masters',
            'Express Booking Masters',
            'Booking Masters',
            'ADM Vehicles',
            'Supplementary Services',
            'Network',
            'Download Requests',
        ],
    },
    {
        title: 'Address',
        translationKey: 'address',
        icon: AddressSetupIcon,
        subitems: [
            'Address Hierarchy',
            'Pincode',
            'Address Register',
        ],
    },
    {
        title: 'Integration Setup',
        translationKey: 'integration_setup',
        icon: IntegrationMarketplaceIcon,
        subitems: [
            'Templates Setup',
            'Third Party',
            'Carrier Partner',
            'Communication Config',
            'Validation Config',
            'Market Place Integration',
        ],
    },
    {
        title: 'Admin',
        translationKey: 'admin',
        icon: AdminSetupIcon,
        subitems: [
            'Security Policy',
            'Currency Conversion',
            'App Flow Config',
            'Configurations',
            'Generic Configurations',
            'Header Mapping',
            'Promocode',
            'Series Consumption Rules',
            'Push Notifications',
            'API Access Key Management',
            'Object Builder',
            'View Builder',
            'Page Builder',
            'Workflow Builder',
            'Ticket Management',
        ],
    },
];

export const SetupSubmenuOnDemand = [
    {
        title: 'User',
        icon: UserIcon,
        subitems: [
            'Employees',
            'App Users',
            'Rider Management',
            'Roster Management',
            'Rider Payout',
            'Attendance',
            'Rider Approval',
        ],
    },
    {
        title: 'Assets',
        icon: AssetsIcon,
        subitems: [
            'Hubs',
            'Transport',
            'Store Configuration',
        ],
    },
    {
        title: 'Address',
        icon: AddressSetupIcon,
        subitems: [
            'Address Hierarchy',
            'Pincode',
            'Address Register',
        ],
    },
    {
        title: 'Integration Setup',
        icon: IntegrationMarketplaceIcon,
        subitems: [
            'Third Party',
            'Carrier Partner',
            'Communication Config',
        ],
    },
    {
        title: 'Admin',
        icon: AdminSetupIcon,
        subitems: [
            'Security Policy',
            'Currency Conversion',
            'App Flow Config',
            'Configurations',
            'Generic Configurations',
            'Header Mapping',
            'Promocode',
            'Series Consumption Rules',
            'Push Notifications',
            'API Access Key Management',
            'Object Builder',
            'View Builder',
            'Page Builder',
            'Workflow Builder',
            'Ticket Management',
        ],
    },
];

export const setupMenuDetailsNew = {
    'Store Configuration': {
        title: 'Store Configuration',
        icon: StoreConfigurationIcon,
        route: '/od/store-configuration',
        isAllowed: masterData => masterData.ondemand_store_configuration?.length && (masterData.show_store_config || masterData.allow_update_store_config),
    },
};

const setupNavMenuItems = {};
setupSideNavConfig.forEach((navObj) => {
    setupNavMenuItems[navObj.title] = navObj;
});

export default setupNavMenuItems;

const setupReverseMapping = {};
SetupSubmenu.forEach((submenuObj) => {
    submenuObj.subitems.forEach((setupMenuKey) => {
        // debugger;
        const finalSelected = `/dashboard/setup${setupRoutes[setupNavMenuItems[setupMenuKey]?.routeId]?.route}`;
        setupReverseMapping[finalSelected] = {
            menuKey: 'setupSubmenu',
            subMenuKey: submenuObj.title,
            subSubMenuKey: setupMenuKey,
        };
    });
});

export const menuUrlReverseMapping = {
    ...setupReverseMapping,
    '/consignments': {
        menuKey: 'consignmentSubmenu',
        subMenuKey: 'consignments',
    },
    [getTmsDownloadRequestRoute()]: {
        menuKey: 'consignmentSubmenu',
        subMenuKey: 'tmsDownloadRequests',
    },
    [getDownloadsRoute()]: {
        menuKey: 'consignmentSubmenu',
        subMenuKey: 'downloads',
    },
    [getConsignmentUploadRequestRoute()]: {
        menuKey: 'consignmentSubmenu',
        subMenuKey: 'uploads',
    },
    [getOpsV1CoordinatorPortalRoute()]: {
        menuKey: 'consignmentSubmenu',
        subMenuKey: 'coordinator_portal',
    },
    [getCRMInOpsRoute()]: {
        menuKey: 'consignmentSubmenu',
        subMenuKey: 'CRM',
        subSubMenuKey: 'crmInOps',
    },
    [getMyCallsRoute()]: {
        menuKey: 'consignmentSubmenu',
        subMenuKey: 'CRM',
        subSubMenuKey: 'showMyCalls',
    },
    [getCallsOverviewRoute()]: {
        menuKey: 'consignmentSubmenu',
        subMenuKey: 'CRM',
        subSubMenuKey: 'showCallsOverview',
    },
    [getDomesticCustomerActivityRoute()]: {
        menuKey: 'consignmentSubmenu',
        subMenuKey: 'customerActivity',
    },
    '/counter-booking': {
        menuKey: 'consignmentSubmenu',
        subMenuKey: 'counterBooking',
    },
    [getPrintLabelRequestRoute()]: {
        menuKey: 'consignmentSubmenu',
        subMenuKey: 'printLabelRequests',
    },
    [getRetailStoreViewRoute()]: {
        menuKey: 'consignmentSubmenu',
        subMenuKey: 'retailStoreView',
    },
    [getTmsBagRoute()]: {
        menuKey: 'consignmentSubmenu',
        subMenuKey: 'tmsBag',
    },
    [getFoodDashboardOrderManagement()]: {
        menuKey: 'ordersSubmenu',
        subMenuKey: 'orderManagament',
    },
    [getFoodDashboardReporting()]: {
        menuKey: 'ordersSubmenu',
        subMenuKey: 'reporting',
    },
    [getFoodDashboardDispatchScreen()]: {
        menuKey: 'ordersSubmenu',
        subMenuKey: 'dispatchManagament',
    },
    [getOpsV1PickupTaskListRoute()]: {
        menuKey: 'FMandLMSubmenu',
        subMenuKey: 'opsv1pickupTaskList',
    },
    [getOpsV1DeliveryTaskListRoute()]: {
        menuKey: 'FMandLMSubmenu',
        subMenuKey: 'opsv1deliverytasklist',
    },
    [getRetailTripManagerRoute()]: {
        menuKey: 'FMandLMSubmenu',
        subMenuKey: 'tripmanager',
    },
    '/retail/ConsolidatedPlanning': {
        menuKey: 'FMandLMSubmenu',
        subMenuKey: 'consolidated_planning',
    },
    [getLiveDashboardRoute()]: {
        menuKey: 'FMandLMSubmenu',
        subMenuKey: 'live_dashboard',
    },
    [getRetailTrackingRoute()]: {
        menuKey: 'FMandLMSubmenu',
        subMenuKey: 'tracking',
    },
    [getAutoAllocateRequestsPageRoute()]: {
        menuKey: 'FMandLMSubmenu',
        subMenuKey: 'retailOptimizationRequests',
    },
    [getCustomTripRequestsRoute()]: {
        menuKey: 'FMandLMSubmenu',
        subMenuKey: 'custom_trip_creation_requests',
    },
    [getOpsV1MediumListRoute()]: {
        menuKey: 'RiderAndVehicleSubmenu',
        subMenuKey: 'rider',
    },
    [getOpsV1TrackingRoute()]: {
        menuKey: 'RiderAndVehicleSubmenu',
        subMenuKey: 'tracking_v1',
    },
    [getOpsV1KmReadingRoute()]: {
        menuKey: 'RiderAndVehicleSubmenu',
        subMenuKey: 'km_reading',
    },
    [getOpsV1AttendanceRoute()]: {
        menuKey: 'RiderAndVehicleSubmenu',
        subMenuKey: 'attendance',
    },
    [getTmsVehicleRoute()]: {
        menuKey: 'RiderAndVehicleSubmenu',
        subMenuKey: 'tmsVehicle',
    },
    [getFoodDashboardRiderManagement()]: {
        menuKey: 'RiderAndVehicleSubmenu',
        subMenuKey: 'riderManagementOD',
    },
    [getFoodDashboardRosterManagement()]: {
        menuKey: 'RiderAndVehicleSubmenu',
        subMenuKey: 'rosterManagementOD',
    },
    [getTmsVehicleAttendanceRoute()]: {
        menuKey: 'RiderAndVehicleSubmenu',
        subMenuKey: 'vehicle_attendance',
    },
    [getTmsOverviewRoute()]: {
        menuKey: 'MiddleMileSubMenu',
        subMenuKey: 'tmsOverview',
    },
    [getTmsISTRoute()]: {
        menuKey: 'MiddleMileSubMenu',
        subMenuKey: 'tmsIST',
    },
    [getShelfBaseRoute()]: {
        menuKey: 'MiddleMileSubMenu',
        subMenuKey: 'shelf',
    },
    [getSupplementoryServicesRoute()]: {
        menuKey: 'MiddleMileSubMenu',
        subMenuKey: 'supplementoryServices',
    },
    [getRiderReconciliationRoute()]: {
        menuKey: 'FinanceOperations',
        subMenuKey: 'riderReconciliation',
    },
    [getCarrierReconciliationRoute()]: {
        menuKey: 'FinanceOperations',
        subMenuKey: 'carrier_reconciliation',
    },
    [getTransactionsHistoryRoute()]: {
        menuKey: 'FinanceOperations',
        subMenuKey: 'transaction',
    },
    [getCarrierTransactionsHistoryRoute()]: {
        menuKey: 'FinanceOperations',
        subMenuKey: 'carrier_transactions',
    },
    [getBankDepositRoute()]: {
        menuKey: 'FinanceOperations',
        subMenuKey: 'bankDeposit',
    },
    [getBankTransactionsHistoryRoute()]: {
        menuKey: 'FinanceOperations',
        subMenuKey: 'bankTransaction',
    },
    [getCashRemittanceRoute()]: {
        menuKey: 'FinanceOperations',
        subMenuKey: 'cashRemittance',
    },
    [getInvoiceRoute()]: {
        menuKey: 'FinanceOperations',
        subMenuKey: 'invoices',
    },
    [getSupplyRequestRoute()]: {
        menuKey: 'FinanceOperations',
        subMenuKey: 'supply_request',
    },
    [getFoodDashboardCODCashSettlement()]: {
        menuKey: 'FinanceOperations',
        subMenuKey: 'codSettlement',
    },
    [getPISSummaryRoute()]: {
        menuKey: 'FinanceOperations',
        subMenuKey: 'PISSummary',
    },
    [getVehicleRequestRoute()]: {
        menuKey: 'RiderAndVehicleSubmenu',
        subMenuKey: 'VehicleRequest',
    },
    [getVendorPayoutRoute()]: {
        menuKey: 'FinanceOperations',
        subMenuKey: 'vendorPayout',
    },
    [getValletTransactionsRoute()]: {
        menuKey: 'FinanceOperations',
        subMenuKey: 'valletTransactions',
    },
    [getManagePISRoute()]: {
        menuKey: 'FinanceOperations',
        subMenuKey: 'managePIS',
    },
    [getThirdPartyViewRoute()]: {
        menuKey: 'thirdPartySubmenu',
        subMenuKey: 'thirdparty',
    },
    [getDomesticTrackRoute()]: {
        menuKey: 'thirdPartySubmenu',
        subMenuKey: 'domesticTracking',
    },
    [getPriceCalculatorRoute()]: {
        menuKey: 'thirdPartySubmenu',
        subMenuKey: 'priceCalculator',
    },
    [getRetailAnalyticsOverviewRoute()]: {
        menuKey: 'AnalyticsSubmenu',
        subMenuKey: 'expressAnalytics',
    },
    '/retail/overview': {
        menuKey: 'AnalyticsSubmenu',
        subMenuKey: 'overview_analytics',
    },
    [getLTLAnalyicsRoute()]: {
        menuKey: 'AnalyticsSubmenu',
        subMenuKey: 'ltlAnalytics',
    },
    '/analytics': {
        menuKey: 'AnalyticsSubmenu',
        subMenuKey: 'analyze',
    },
    '/analytics/dashboard': {
        menuKey: 'AnalyticsSubmenu',
        subMenuKey: 'queryBuilder',
    },
    '/analytics/reports': {
        menuKey: 'AnalyticsSubmenu',
        subMenuKey: 'queryBuilder',
    },
    [getGenericDownloadRequestsRoute()]: {
        menuKey: 'AnalyticsSubmenu',
        subMenuKey: 'generic-download-requests',
    },
    [getFoodDashboardAnalytics()]: {
        menuKey: 'AnalyticsSubmenu',
        subMenuKey: 'analytics',
    },
    [getRoutingPlaygroundRoute()]: {
        menuKey: 'shipsyAISubmenu',
        subMenuKey: 'routingPlayground',
    },
    [getTerritoryOptimizationRoute()]: {
        menuKey: 'shipsyAISubmenu',
        subMenuKey: 'territoryOptimization',
    },
    [getGeocodingPlaygroundRoute()]: {
        menuKey: 'shipsyAISubmenu',
        subMenuKey: 'geocodingPlayground',
    },

    [getIntegrationMarketplaceRoute()]: {
        menuKey: 'integrationSubmenu',
        subMenuKey: 'integrationMarketplace',
    },
    [getRetailERPSyncRoute()]: {
        menuKey: 'integrationSubmenu',
        subMenuKey: 'erpSync',
    },
    [API_PLAYGROUND_BASE_PATH]: {
        menuKey: 'integrationSubmenu',
        subMenuKey: 'apiPlayground',
    },

    [getRetailSupportPortalRoute()]: {
        menuKey: 'supportToolsSubmenu',
        subMenuKey: 'retailSupportPortal',
    },
    [getDevToolsRoute()]: {
        menuKey: 'supportToolsSubmenu',
        subMenuKey: 'devtools',
    },
    [getFoodDashboardNotifications()]: {
        menuKey: 'supportToolsSubmenu',
        subMenuKey: 'helpdeskNotifications',
    },
    '/od/store-configuration': {
        menuKey: 'setupSubmenu',
        subMenuKey: 'Assets',
        subSubMenuKey: 'Store Configuration',
    },
    '/dashboard/setup/miscHubOps/attendance': {
        menuKey: 'setupSubmenu',
        subMenuKey: 'User',
        subSubMenuKey: 'Attendance',
    },
    // []: {
    //     menuKey: '',
    //     subMenuKey: '',
    // },
};
