
import {
  FETCH_SUPPORT_TICKETS_STATUS_MASTER,
  FETCH_SUPPORT_TICKETS_STATUS_MASTER_ERROR,
  FETCH_SUPPORT_TICKETS_STATUS_MASTER_LOADING,
} from 'src/actions/constants';
import { fetchSupportTicketsStatusMaster } from './SupportTickets.api';

export const fetchSupportTicketsStatusMasterData = () => async (dispatch) => {

  dispatch({
    type: FETCH_SUPPORT_TICKETS_STATUS_MASTER_LOADING,
    data: true,
  });

  const response = await fetchSupportTicketsStatusMaster();

  if (response.isSuccess) {
    dispatch({
      type: FETCH_SUPPORT_TICKETS_STATUS_MASTER,
      data: response.data
    });
  } else {
    dispatch({
      type: FETCH_SUPPORT_TICKETS_STATUS_MASTER_ERROR,
      data: response.errorMessage,
    });
  }

  dispatch({
    type: FETCH_SUPPORT_TICKETS_STATUS_MASTER_LOADING,
    data: false,
  });
}
