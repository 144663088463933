export const eventTypes = {
  lia: {
    ticket_comment: 'issue_comment',
    ticket_status_changed: 'issue_status_changed',
    ticket_created: 'issue_created',
    ticket_assignee_changed: 'issue_assignee_changed',
  },
  support_ticket_notification: {
    ticket_comment: 'issue_comment',
    ticket_status_changed: 'issue_status_changed',
    ticket_assignee_added: 'issue_assignee_added',
    ticket_assignee_removed: 'issue_assignee_removed',
  },
};

export const colorsForActions = {
  open: 'purple',
  actionTaken: 'cyan',
  closed: 'default',
  acknowledged: 'orange',
};

export const colorsForTag = ['magenta', 'red', 'volcano', 'orange', 'gold', 'lime', 'green', 'cyan', 'blue', 'geekblue', 'purple'];

export const notificationSettings = [
  {
    label: 'LIA Co-Pilot',
    value: 'lia',
  },
  {
    label: 'Support Tickets',
    value: 'support_ticket_notification',
  },
];

export const NOTIFICATION_COLLECTION = 'notification';
export const USER_PREFERENCE_COLLECTION= 'userPreference';
export const USER_NOTIFICATION_COUNT_COLLECTION = 'userNotificationCount';
