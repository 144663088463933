import * as React from 'react';
import { message, Spin } from 'antd';
import * as  moment from 'moment';
import { Link } from 'react-router-dom';
import { bindActionCreators } from 'redux';
import { getSupportTicketRedirectionRoute } from 'src/routing/utils';
import { connect } from 'react-redux';
import { fetchFilteredSupportTickets } from './SupportTickets.api';
import FixedHeaderTable from 'src/components/common/FixedHeaderTable/FixedHeaderTable';
import { fetchSupportTicketsStatusMasterData } from './SupportTickets.actions';
import { LocalStorageKeys } from 'src/services/localStorage';

const SupportTicketsTable = (props: any) => {
    const {
        tripReferenceNumber,
        columnsToShow,
        fetchSupportTicketsStatusMasterData,
        errorMessage,
        statusMaster,
        referenceNumber,
    } = props;
    const [loading, setLoading] = React.useState(false);
    const [supportTicketsData, setSupportTicketsData] = React.useState([]);
    const [statusMasterMap, setStatusMasterMap] = React.useState({});
    const organisationId = localStorage.getItem(LocalStorageKeys.__ORGANISATION_ID__);

    React.useEffect(() => {
        if (tripReferenceNumber || referenceNumber) {
            fetchSupportTickets();
        }
    }, []);

    React.useEffect(() => {
        if (errorMessage) {
            message.error(errorMessage);
        }
    }, [errorMessage]);

    React.useEffect(() => {
        if (
            Array.isArray(statusMaster) &&
            statusMaster.length &&
            Array.isArray(statusMaster?.[0]?.results) &&
            statusMaster?.[0]?.results?.length
        ) {
            const statusMasterIdMap = statusMaster?.[0]?.results?.reduce((acc, curr) => {
                acc[curr.id] = curr;
                return acc;
            }, {});
            setStatusMasterMap(statusMasterIdMap);
        }
    }, [statusMaster]);

    const fetchSupportTickets = async () => {
        setLoading(true);
        if (!Array.isArray(statusMaster) || !statusMaster?.length) {
            await fetchSupportTicketsStatusMasterData();
        }
        const params = {
            trip_reference_number: tripReferenceNumber,
            reference_number: referenceNumber,
        };
        const response = await fetchFilteredSupportTickets(params);
        if (response.isSuccess) {
            setSupportTicketsData(response.data?.[0]?.results);
        }
        else {
            message.error(response.errorMessage);
        }
        setLoading(false);
    };

    const renderTicketId = (record) => {
        if (!record.sequence_id) return '-';
        return (
            <Link
                target={'_blank'}
                to={getSupportTicketRedirectionRoute(organisationId, record.project, record.id)}
            >
                Ticket-{record.sequence_id}
            </Link>
        );
    }

    const getColumnsForTable = () => {
        const columns = columnsToShow?.map((col) => {
            return (
                col && {
                    dataIndex: col.id,
                    title: col.pretty_name,
                    width: col.key === 'ticket_name' ? 180 : 80,
                    render: (x, record, index) => {
                        switch (col.key) {
                            case 'ticket_sequence_id':
                                return renderTicketId(record);
                            case 'ticket_name':
                                return record.name;
                            case 'ticket_reference_number':
                                return record.reference_number;
                            case 'ticket_state_id':
                                return statusMasterMap?.[record.state_id]?.name || record.state_id;
                            case 'ticket_created_at':
                                return record.created_at ? moment(record.created_at).format('DD-MM-YYYY HH:mm') : '';
                            default:
                                return x;
                        }
                    },
                }
            );
        });
        return columns.filter((column) => column);
    };

    return (
        <>
            {loading ?
                <Spin /> :
                <FixedHeaderTable
                    locale={{ emptyText: 'No data found' }}
                    rowKey={(record: any) => record['id']}
                    dataSource={supportTicketsData}
                    pagination={false}
                    columns={getColumnsForTable()}
                />
            }
        </>
    );
};

const mapStateToProps = ({ supportTicketsReducer }, ownProps) => ({
    isLoading: supportTicketsReducer?.isLoading,
    errorMessage: supportTicketsReducer?.errorMessage,
    statusMaster: supportTicketsReducer?.statusMaster,
});

const mapDispatchToProps = (dispatch) => {
    return bindActionCreators({
        fetchSupportTicketsStatusMasterData,
    }, dispatch);
};

export default connect(mapStateToProps, mapDispatchToProps)(SupportTicketsTable);
