import React, { useMemo } from 'react';
import { createUseStyles } from 'react-jss';
import { ArrowRightOutlined } from '@ant-design/icons';
import { Tag } from 'antd';
import { isMobile } from 'react-device-detect';
import { colorsForActions, colorsForTag } from '../notificationConstants';
import { TimeAndBadgeComponent } from './common';
import createMentionPlugin from '@draft-js-plugins/mention';
import lodash from 'lodash';
import { EditorState, convertFromRaw, CompositeDecorator } from 'draft-js';
import Editor from '@draft-js-plugins/editor';

const useStyle = createUseStyles({
  notificationDescription: {
    display: 'flex',
    flexDirection: 'column',
    gap: '5px',
    marginTop: '4px',
  },
  headerTextStyle: {
    color: '#111',
    fontSize: '12px',
    fontWeight: 600,
  },
  notificationBody: {
    display: 'flex',
    flexDirection: 'row',
    gap: '5px',
    justifyContent: 'space-between',
  },
  messageText: {
    fontStyle: 'italic',
    fontSize: '10px',
  },
});

const createStatus = (name) => {
  const spacedWords = lodash.words(name);
  const capitalizedWords = lodash.map(spacedWords, (word) =>
    lodash.upperFirst(word),
  );
  return capitalizedWords.join(' ');
};

export const NotificationDescription = (props) => {
  const classes = useStyle();
  const {
    item,
    title = '',
    notificationId,
    notificationStatus,
    setNotificationStatus,
    sourceName,
  } = props;
  const { MentionSuggestions, plugins } = useMemo(() => {
    const mentionPlugin = createMentionPlugin({
      entityMutability: 'IMMUTABLE',
      mentionPrefix: '@',
      supportWhitespace: true,
    });

    const { MentionSuggestions } = mentionPlugin;
    const plugins = [mentionPlugin];
    return { plugins, MentionSuggestions };
  }, []);
  const decorators = lodash.flattenDeep(
    plugins.map((plugin) => plugin.decorators),
  );
  const decorator = new CompositeDecorator(
    decorators.filter((decorator, index) => index !== 1),
  );
  return (
    <div className={classes.notificationDescription}>
      {title && <div className={classes.headerTextStyle}>{title}</div>}
      {(isMobile ||
        item.extraData?.comment ||
        item.extraData?.previousStatus ||
        item.extraData?.currentAssignee) && (
        <div className={classes.notificationBody}>
          <div>
            {item.extraData?.comment && (
              <div className={classes.messageText}>
                <Editor
                  readOnly
                  plugins={plugins}
                  editorState={EditorState.createWithContent(
                    convertFromRaw(item.extraData?.comment),
                    decorator,
                  )}
                />
              </div>
            )}
            {item.extraData?.previousStatus &&
              item.extraData?.currentStatus && (
                <div style={{ marginTop: '4px' }}>
                  <Tag color={colorsForActions[item.extraData?.previousStatus]}>
                    {createStatus(item.extraData?.previousStatus)}
                  </Tag>
                  <ArrowRightOutlined />{' '}
                  <Tag color={colorsForActions[item.extraData?.currentStatus]}>
                    {createStatus(item.extraData?.currentStatus)}
                  </Tag>
                </div>
              )}
            {item.extraData?.currentAssignee && (
              <div style={{ marginTop: '4px' }}>
                {sourceName === 'support_ticket_notification' ? (
                  // Show only the current assignee tag
                  <Tag
                    color={
                      colorsForTag[
                        item.extraData?.currentAssignee.length ?? 0 % 11
                      ]
                    }
                  >
                    {item.extraData?.currentAssignee}
                  </Tag>
                ) : sourceName === 'lia' &&
                  item.extraData?.previousAssignee &&
                  item.extraData?.currentAssignee ? (
                  // Original handling for "lia"
                  <>
                    <Tag
                      color={
                        colorsForTag[
                          item.extraData?.previousAssignee.length ?? 0 % 11
                        ]
                      }
                    >
                      {item.extraData?.previousAssignee}
                    </Tag>
                    <ArrowRightOutlined />{' '}
                    <Tag
                      color={
                        colorsForTag[
                          item.extraData?.currentAssignee.length ?? 0 % 11
                        ]
                      }
                    >
                      {item.extraData?.currentAssignee}
                    </Tag>
                  </>
                ) : null}
              </div>
            )}
          </div>
          {isMobile && (
            <TimeAndBadgeComponent
              item={item}
              notificationId={notificationId}
              notificationStatus={notificationStatus}
              setNotificationStatus={setNotificationStatus}
            />
          )}
        </div>
      )}
    </div>
  );
};
