import { GET } from 'src/api/apiHandler';
import { FETCH_FILTERED_SUPPORT_TICKETS, FETCH_TICKET_STATUS_MASTER } from 'src/api/routes';
import { API_BASE_URL } from 'src/globals';

export const fetchFilteredSupportTickets = async (params) => {
  return GET(`${API_BASE_URL}${FETCH_FILTERED_SUPPORT_TICKETS}`, params);
};

export const fetchSupportTicketsStatusMaster = async () => {
  return GET(`${API_BASE_URL}${FETCH_TICKET_STATUS_MASTER}`);
};
