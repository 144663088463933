import { getKeyArrayFromObject } from 'src/utils/utils';
import { getViewBuckets, getViewControl } from '../utils/genericConfig/utils';

const pagination = {
  last_trip_id: {
    path: 'pagination',
    fn : (value) => {
      return getLastRowId(value);
    },
  },
  last_sort_field_value: {
    path: 'pagination',
    fn : (value) => {
      return getLastSortedColumnValue(value);
    },
  },
  result_per_page: 'pagination.perPageCount',
  next_or_prev: 'pagination.nextOrPrev',
};
const sort = {
  sort_by:  {
    path: 'sort.sortBy',
    fn : (value) => {
      return value && value.length ? value : 'created_at';
    },
  },
  descending_order: 'sort.descendingOrder',
};
const requestSchema = {
  ...pagination,
  ...sort,
  from_date: 'globalAppliedFilters.startDate',
  to_date: 'globalAppliedFilters.endDate',
  date_field: 'globalAppliedFilters.dateFilterType',
  organisation_reference_number: 'globalAppliedFilters.searchValue',
  hub_id: {
    path : 'currHub',
    fn : value => value.id || null,
  },
  bucket: 'bucket',
  trip_type: {
    path : 'appliedFilters',
    fn : value => getBucketApiValue(value.tripType),
  },
  vehicle_type : {
    path : 'appliedFilters',
    fn : value => getBucketApiValue(value.vehicleType),
  },
  route: 'appliedFilters.route_search',
  vehicle_id: {
    path : 'appliedFilters.vehicle_id',
    fn : vehicle => vehicle?.value || '',
  },
  worker_id: {
    path : 'appliedFilters.worker_id',
    fn : worker => worker?.value || '',
  },
  is_reconciliation_complete: {
    path : 'appliedFilters',
    fn : value => getReconciliationValue(value.is_reconciliation_complete),
  },
  is_hand_delivery_trip: {
    path: 'appliedFilters.is_hand_delivery_trip',
    fn : (value) => {
      switch (value) {
        case 'true':
          return true;
        case 'false':
          return false;
        default:
          return undefined;
      }
    },
  },
  status_list: {
    path: 'appliedFilters.status',
    fn : (value) => {
      return getKeyArrayFromObject(value);
    },
  },
  is_billed: {
    path: 'appliedFilters.billed_status',
    fn : (value) => {
      switch (value) {
        case 'true':
          return true;
        case 'false':
          return false;
        default:
          return undefined;
      }
    },
  },
  is_verified: {
    path: 'appliedFilters.verified_status',
    fn : (value) => {
      switch (value) {
        case 'true':
          return true;
        case 'false':
          return false;
        default:
          return undefined;
      }
    },
  },
  unassigned_vehicles_only: {
    path: 'appliedFilters.unassigned_vehicles_only',
    fn : (value) => {
      switch (value) {
        case 'true':
          return true;
        case 'false':
          return false;
        default:
          return undefined;
      }
    },
  },
  unassigned_workers_only: {
    path: 'appliedFilters.unassigned_workers_only',
    fn : (value) => {
      switch (value) {
        case 'true':
          return true;
        case 'false':
          return false;
        default:
          return undefined;
      }
    },
  },
};
const getBucketApiValue = (bucket) => {
  if (bucket === 'all') {
    return undefined;
  }
  return bucket;
};
const getReconciliationValue = (val) => {
  switch (val) {
    case 'yes':
      return true;
    case 'no':
      return false;
    default:
      return undefined;

  }
};
const getLastSortedColumnValue = (pagination) => {
  switch (pagination.nextOrPrev) {
    case 'prev':
      return pagination.lastSortedColumnValuePrev;
    case 'next':
      return pagination.lastSortedColumnValueNext;
    default:
      return null;
  }
};

const getLastRowId = (pagination) => {
  switch (pagination.nextOrPrev) {
    case 'prev':
      return pagination.lastItemIdPrev;
    case 'next':
      return pagination.lastItemIdNext;
    default:
      return null;
  }
};
const responseSchema = {
  isNextPresent: 'is_next_present',
  lastItemIdNext: 'last_trip_id_next',
  lastItemIdPrev: 'last_trip_id_prev',
  lastSortedColumnValueNext: 'last_field_value_next',
  lastSortedColumnValuePrev: 'last_field_value_prev',
  page_data: {
    path : 'page_data',
    fn : (data) => {
      for (const trip of data) {
        trip.uniqueId = trip.id;
        if (trip.secondary_trips && trip.secondary_trips.length) {
          for (const sTrip of trip.secondary_trips) {
            sTrip.uniqueId = `${sTrip.id}_child`;
          }
        }
      }
      return data;
    },
  },
};
const requestSchemaSummaryMetrics = {
  from_date: 'globalAppliedFilters.startDate' ,
  to_date: 'globalAppliedFilters.endDate' ,
  date_field: 'globalAppliedFilters.dateFilterType' ,
  hub_id: {
    path : 'currHub',
    fn : value => value.id || null,
  },
  bucket_ids :{
    path : 'viewType',
    fn : viewType => getViewControl(viewType).buckets,
  },
};
const responseSchemaSummaryMetrics = {
  data: {
    path:'data',
    fn : value => value[0],
  },
};
export { requestSchema, responseSchema, requestSchemaSummaryMetrics, responseSchemaSummaryMetrics };
