import * as React from 'react';
import withStyles from 'react-jss';
import { connect } from 'react-redux';
import { CssType, ThemeType } from '@theming/jssTypes';
import { Button, Table, Modal, Input, message, Tooltip, Carousel } from 'antd';
import { EditOutlined, WarningFilled } from '@ant-design/icons';
import OpenImageModal from './OpenImageModal';
import lodash from 'lodash';
import { getConsignmentStatusPrettyName } from '@utils/consignmentStatus';
import {
  getColumnList,
  addLBHColumn,
  unnecessaryColumns,
} from './PieceDetailsTabConfig';
import { getDetailsRoute } from '@routing/utils';
import { Link } from 'react-router-dom';
import { addCommentsToPieces } from '@api/dashboard';
import TextWithEllipsisAndTooltip from '@components/common/TextWithEllipsisAndTooltip';
import { LeftCircleOutlined, RightCircleOutlined } from '@ant-design/icons';
import FixedHeaderTable from 'src/components/common/FixedHeaderTable/FixedHeaderTable';
import * as moment from 'moment';
import CustomColumnsModal from 'src/components/pages/domestic/CustomColumnsModal';
import { bindActionCreators } from 'redux';
import { useTranslation } from 'react-i18next';
import { SettingIcon } from 'src/components/common/icons/SettingsIcon';
import { updateUserConsignmentPieceDetails } from 'src/api/genericConsignmentView';
import { setColumnsPieceDetails } from 'src/actions/genericConsignmentActions';
import { useDispatch } from 'react-redux';
import PodImagePreviewModal from '../setup/GenericApprovalWorkflow/ApprovalRequests/PodImagePreviewModal';
const styles = (theme: ThemeType): CssType => ({
  tableContainer: {
    flex: '1 1 0',
    '& .ant-table-tbody > tr > td, .ant-table-thead > tr > th': {
      padding: 9,
      textAlign: 'center',
    },
  },
  underline: {
    textDecoration: 'underline',
    color: '#065492',
    cursor: 'pointer',
  },
  editIcon: {
    marginLeft: 10,
    color: '#085492',
  },
  settingsIcon: {
    display: 'flex',
    color: 'rgba(0, 0, 0, 0.85)',
    justifyContent: 'center',
    alignItems: 'center',
    height: '100%',
    margin: '0 1rem',
  },
});

const PieceDetailsModal = (props: any) => {
  const {
    sorterImageBucketUrl,
    isShowLBH,
    viewType,
    columns,
    data,
    reload,
    cnReferenceNumber,
  } = props;
  const { isVisible, classes, pieceDetails, fullColumnList, columnsToShow, bucket } = props;
  const dispatch = useDispatch();
  const [modalData, setModalData] = React.useState({
    isImageModalVisible: false,
    imageUrl: '',
  });
  const [imageVideoModalData, setImageVideoModalData] = React.useState({
    isImageVideoModalData: false,
    images: [],
    videos: [],
  });
  const [commentModal, setCommentModal] = React.useState({
    isVisible: false,
    fieldValue: '',
    pieceReferenceNumber: '',
    referenceNumber: '',
    isLoading: false,
  });
  const [showCustomColumnModal, setShowCustomColumnModal] = React.useState(false);
  const [isUploading, setIsUploading] = React.useState(false);
  const handleOpenImageModal = (imageUrl) => {
    setModalData({
      isImageModalVisible: true,
      imageUrl,
    });
  };

  const [imageModalPreviewProps, setImageModalPreviewProps] = React.useState<{
    isVisible: boolean,
    imageList: string[],
  }>({
    isVisible: false,
    imageList: [],
  });

  const handleCloseImageModal = () => {
    setModalData({
      ...modalData,
      isImageModalVisible: false,
    });
  };

  const handleOpenImageVideoModal = (images = [], videos = []) => {
    setImageVideoModalData({
      isImageVideoModalData: true,
      images,
      videos,
    });
  };

  const handleCloseImageVideoModal = () => {
    setImageVideoModalData({
      isImageVideoModalData: false,
      images: [],
      videos: [],
    });
  };

  const showEditCommentModal = (pieceReferenceNumber, value = null) => {
    setCommentModal({
      ...commentModal,
      fieldValue: value,
      isVisible: true,
      pieceReferenceNumber,
      referenceNumber: cnReferenceNumber,
    });
  };

  const handleCommentModalClose = () => {
    setCommentModal({
      ...commentModal,
      isVisible: false,
      fieldValue: '',
      pieceReferenceNumber: '',
      referenceNumber: '',
    });
  };

  const handleEditCommentSubmit = async () => {
    const requestBody = {
      comment: commentModal.fieldValue,
      piece_reference_number: commentModal.pieceReferenceNumber || null,
      reference_number: commentModal.referenceNumber,
    };
    setCommentModal({
      ...commentModal,
      isLoading: true,
    });
    const response = await addCommentsToPieces(requestBody);
    if (response.isSuccess) {
      message.success(
        `Comments updated for ${commentModal.pieceReferenceNumber}`,
      );
      reload();
    } else {
      message.error(
        `Unable to update comments for ${commentModal.pieceReferenceNumber}.`,
      );
    }
    setCommentModal({
      isVisible: false,
      pieceReferenceNumber: '',
      fieldValue: '',
      referenceNumber: '',
      isLoading: false,
    });
  };

  const handleCustomColumnSetColumns = async (items) => {
    const { setColumnsPieceDetails } = props;
    const piece_detail_column_list = items;
    const body = {
      bucket,
      piece_detail_column_list,
    };
    setIsUploading(true);
    const res = await updateUserConsignmentPieceDetails(body);
    if (res?.isSuccess) setColumnsPieceDetails(piece_detail_column_list);
    else message.error(res?.errorMessage);
    setIsUploading(false);
    setShowCustomColumnModal(false);
  };

  const generateDataColumns = () => {
    const { t } = useTranslation();
    const columnData: any = [];
    // const {sorter_image_bucket_url, isShowLBH} = this.props;
    let indexList = (bucket && bucket === 'consignment_piece_details') ? columnsToShow : columns;

    if (isShowLBH) {
      indexList = [
        ...indexList.filter(
          (column) =>
            unnecessaryColumns.findIndex((y) => y === column.column_id) === -1,
        ),
        ...addLBHColumn,
      ];
    }
    indexList.forEach((item) => {
      const itemToPush = {};
      const itemId = item.column_id || item.id;
      itemToPush['title'] = t(item.pretty_name);
      itemToPush['dataIndex'] = itemId;
      itemToPush['width'] = item.width || 80;
      itemToPush['key'] = itemId;

      if (itemId === 'serial_number') {
        itemToPush['width'] = 50;
        itemToPush['render'] = (text, record, index) => {
          return <span> {index + 1}</span>;
        };
      }
      if (itemId === 'status') {
        itemToPush['width'] = 150;
        itemToPush['render'] = (x, record) => {
          return record._internal ? (
            <span>
              {t(getConsignmentStatusPrettyName(record._internal.status))}
            </span>
          ) : null;
        };
      }
      if (itemId === 'delivery_failure_reason') {
        itemToPush['render'] = (x, record) => {
          return record._internal &&
            (record._internal.status === 'attempted' ||
              record._internal.status === 'not_picked_up') ? (
            <span>{t(x)}</span>
          ) : null;
        };
      }
      if (itemId === 'volume') {
        itemToPush['title'] = (
          <span>
           {t ('Volume')}(m<sup>3</sup>)
          </span>
        );
        itemToPush['render'] = (x, record) => {
          const dim = record.width ? 'width' : 'breadth';
          const LBH = ['length', 'height', dim];
          const isPresent = LBH.every((dimension) =>
            Object.hasOwnProperty.bind(record)(dimension),
          );
          const calVolume = isPresent
            ? Math.round(
                (record.length * record[dim] * record.height + Number.EPSILON) *
                  100,
              ) / 100
            : '';
          const volume = record.volume ? record.volume : calVolume;
          return <span>{`${volume}`}</span>;
        };
      }
      if (itemId === 'breadth') {
        itemToPush['render'] = (x, record) => {
          const value = record.breadth || record.width;
          return <span>{value ? value : null}</span>;
        };
      }
      if (itemId === 'weight') {
        itemToPush['render'] = (x, record) => {
          const weight = record.weight ? record.weight : '';
          return <span>{`${weight}`}</span>;
        };
      }
      if (itemId === 'piece_product_code') {
        itemToPush['render'] = (x, record) => {
          const code = record.piece_product_code
            ? record.piece_product_code
            : '';
          return <span>{`${code}`}</span>;
        };
      }
      if (itemId === 'reference_image_url') {
        itemToPush['render'] = (x, record) => {
          if (!record.reference_image_url) return null;
          return (
            <div>
              <Button
                onClick={() => {
                  handleOpenImageModal(record.reference_image_url);
                }}
                type="primary"
              >
                {t('Open Image')}
              </Button>
            </div>
          );
        };
      }
      if (itemId === 'reference_number' && !props.deletedPieces) {
        itemToPush['render'] = (x, record) => {
          return (
            <Link
              target={'_blank'}
              to={getDetailsRoute(record.reference_number)}
            >
              {t(x)}
            </Link>
          );
        };
      }
      if (itemId === 'image') {
        itemToPush['render'] = (x, record) => {
          const sorter_image_path = lodash.get(
            record,
            '_internal.sorter_image_link',
            '',
          );
          const sorter_image_url =
            sorter_image_path && sorterImageBucketUrl
              ? `${sorterImageBucketUrl}${sorter_image_path}`
              : '';
          const pieces_images =
            lodash.get(record, '_internal.image_url_list') || [];
          const pieces_videos =
            lodash.get(record, '_internal.video_url_list') || [];
          const image_url = sorter_image_url ? sorter_image_url : x;
          if (
            (Array.isArray(pieces_images) && pieces_images.length) ||
            (Array.isArray(pieces_videos) && pieces_videos.length)
          ) {
            return (
              <div>
                <Button
                  onClick={() => {
                    handleOpenImageVideoModal(pieces_images, pieces_videos);
                  }}
                  type="primary"
                >
                  {t('Click Here')}
                </Button>
              </div>
            );
          } else if (image_url) {
            return (
              <div>
                <Button
                  onClick={() => {
                    handleOpenImageModal(image_url);
                  }}
                  type="primary"
                >
                  {t('Open Image')}
                </Button>
              </div>
            );
          }
          return null;
        };
      }
      if (itemId === 'comment') {
        itemToPush['render'] = (x, record) => {
          return record.comment ? (
            <div>
              <TextWithEllipsisAndTooltip width="100px">
                {record.comment}
              </TextWithEllipsisAndTooltip>
              <EditOutlined
                className={classes.editIcon}
                onClick={() =>
                  showEditCommentModal(record.reference_number, record.comment)
                }
              />
            </div>
          ) : (
            <div
              className={classes.underline}
              onClick={() => showEditCommentModal(record.reference_number)}
            >
              {t('Add Comment')}
            </div>
          );
        };
      }
      if (itemId === 'quantity_delivered') {
        itemToPush['render'] = (x, record) => {
          return record.hasOwnProperty('quantity_delivered') ? (
            <span>{record.quantity_delivered}</span>
          ) : null;
        };
      }
      if (itemId === 'deleted_at') {
        itemToPush['render'] = (x, record) => {
          const localTimeStamp = record.deleted_at ? moment(record.deleted_at).local().format('YYYY-MM-DD HH:mm') : null;
          return localTimeStamp ? (
            <div>
              {localTimeStamp}
            </div>
          ) : null;
        };
      }
      if (itemId === 'proof_image') {
        itemToPush['render'] = (x, record) => {
          const proofImageList = [
            ...(record?.['_internal']?.image_url_list || []),
            ...(record?.['_internal']?.pop_image_list || []),
          ];
          if (!proofImageList?.length) return null;
          return (
            <a
              onClick={() => setImageModalPreviewProps({
                isVisible: true,
                imageList: proofImageList?.map((item) => item),
              })}
            >
              {t('View')}
            </a>
          );
        };
      }
      if (item.type === 'boolean') {
        itemToPush['render'] = (x, record) => {
          return record.hasOwnProperty(itemId) ? (<div>{ record[itemId] ? 'Yes' : 'No' }</div>) : null;
        };
      }
      if (['pod_raised_at','pod_approved_at','pod_rejected_at'].includes(itemId)) {
        itemToPush['render'] = (x, record) => {
          const timestamp = record?.['_internal']?.pod_approval_details?.[itemId];
          return timestamp ? moment(timestamp).format('YYYY-MM-DD HH:mm:ss') : null;
        };
      }
      if (['pod_source','pod_status','pod_raised_by'].includes(itemId)) {
        itemToPush['render'] = (x, record) => {
          const value = record?.['_internal']?.pod_approval_details?.[itemId];
          return value ? <span>{value}</span> : null;
        };
      }
      columnData.push(itemToPush);
    });
    return columnData;
  };

  const renderImageModal = () => {
    if (!imageModalPreviewProps.imageList?.length) {
      return null;
    }
    return (
      <PodImagePreviewModal
        isVisible={imageModalPreviewProps.isVisible}
        handleClose={() => setImageModalPreviewProps({
          isVisible: false,
          imageList: [],
        })}
        imageList={imageModalPreviewProps.imageList}
      />
    );
  };

  const header = `${pieceDetails.selectedReferenceNumber} Piece Detail View`;
  return (
    <div className={classes.tableContainer}>
      {modalData.isImageModalVisible ? (
        <OpenImageModal
          imageUrl={modalData.imageUrl}
          isVisible={modalData.isImageModalVisible}
          onModalClose={handleCloseImageModal}
        ></OpenImageModal>
      ) : null}
      {imageVideoModalData.isImageVideoModalData ? (
        <Modal
          className={classes.modalContainer}
          visible={imageVideoModalData.isImageVideoModalData}
          onCancel={() => handleCloseImageVideoModal()}
          footer={null}
        >
          <Carousel
            className={classes.carouselContainer}
            prevArrow={<LeftCircleOutlined />}
            nextArrow={<RightCircleOutlined />}
            arrows
          >
            {imageVideoModalData?.images?.map((data) => (
              <div key={data}>
                <img object-fit="cover" style={{ width: '100%' }} src={data} />
              </div>
            ))}
            {imageVideoModalData?.videos?.map((data) => (
              <div key={data} style={{ maxWidth: '100%' }}>
                <video controls style={{ maxWidth: '100%' }}>
                  <source src={data} type="video/mp4" />
                  Your browser does not support the video tag.
                </video>
              </div>
            ))}
          </Carousel>
        </Modal>
      ) : null}
      {bucket && bucket === 'consignment_piece_details' && <>
        <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'flex-end' }}>
          <a className={classes.settingsIcon}>
            <SettingIcon
              onClick={() => {
                setShowCustomColumnModal(true);
              }}
            />
          </a>
        </div>
        <CustomColumnsModal
          isVisible={showCustomColumnModal}
          onModalClose={() => setShowCustomColumnModal(false)}
          setColumns={handleCustomColumnSetColumns}
          fullColumnList={fullColumnList}
          columnsToShow={columnsToShow}
          isUploading={isUploading}
        />
      </>}
      <FixedHeaderTable
        locale={{ emptyText: 'No pieces' }}
        pagination={false}
        dataSource={data}
        columns={generateDataColumns()}
        resizable={true}
      />
      <Modal
        title={'Add Comment'}
        width={450}
        visible={commentModal.isVisible}
        okText={'Add'}
        onCancel={handleCommentModalClose}
        onOk={handleEditCommentSubmit}
        confirmLoading={commentModal.isLoading}
        destroyOnClose
      >
        <Input.TextArea
          rows={4}
          onChange={(e) =>
            setCommentModal({ ...commentModal, fieldValue: e.target.value })
          }
          defaultValue={commentModal.fieldValue}
        />
      </Modal>
      {imageModalPreviewProps.isVisible && renderImageModal()}
    </div>
  );
};

const mapStateToProps = ({ masterData }) => {
  return {
    viewType: masterData.viewType,
    isShowLBH: lodash.get(
      masterData,
      'do_not_fetch_crm_piece_details_from_child_cn',
      false,
    ),
    sorterImageBucketUrl: lodash.get(
      masterData,
      'sorter_info.sorter_image_bucket_url',
      '',
    ),
    columnsToShow: masterData.ops_consignment_piece_detail_column_list || [],
    fullColumnList: masterData.ops_consignment_piece_detail_full_column_list || [],
  };
};

const mapDispatchToProps = (dispatch) => {
  return bindActionCreators({
    setColumnsPieceDetails,
  }, dispatch);
};

const PieceDetailsModalStyled = withStyles(styles, { injectTheme: true })(
  PieceDetailsModal,
);
export default connect(mapStateToProps, mapDispatchToProps)(PieceDetailsModalStyled);
