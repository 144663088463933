import {
  FETCH_SUPPORT_TICKETS_STATUS_MASTER,
  FETCH_SUPPORT_TICKETS_STATUS_MASTER_ERROR,
  FETCH_SUPPORT_TICKETS_STATUS_MASTER_LOADING,
} from 'src/actions/constants';

const defaultState = {
  statusMaster: [],
  isLoading: false,
  errorMessage: '',
};

export default function (state: any = defaultState, action) {

  switch (action.type) {

    case FETCH_SUPPORT_TICKETS_STATUS_MASTER_LOADING: {
      return {
        ...state,
        isLoading: action.data,
      };
    }

    case FETCH_SUPPORT_TICKETS_STATUS_MASTER: {
      return {
        ...state,
        statusMaster: action.data,
        errorMessage: '',
      };
    }

    case FETCH_SUPPORT_TICKETS_STATUS_MASTER_ERROR: {
      return {
        ...state,
        errorMessage: action.data,
      };
    }

    default: return state;
  }
}
