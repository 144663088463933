import * as React from 'react';
import withStyles from 'react-jss';
import { Modal, Image, Carousel } from 'antd';
import { RightCircleOutlined, LeftCircleOutlined, DownloadOutlined } from '@ant-design/icons';
import PdfRenderer from '@components/common/PdfRenderer';

const styles = ({
    modalContainer: {
        padding: '1rem 0',
        paddingBottom: '3rem',
    },
    imageGalleryContainer: {
        justifyContent: 'center',
        display: 'flex',
        alignItems: 'center',
        fontSize: '40px',
    },
    carouselContainer: {
        padding: '1.5rem',
        maxWidth: '100%',
        '& span > svg': {
            width: '120%',
            height: '120%',
        },
    },
    pdfContainer: {
        display: 'flex',
        flexDirection: 'column',
    },
});

const { useRef } = React;

const GalleryModal = (props: any) => {
    const {
        handleModalClose,
        isVisible,
        classes,
        imageVideoGalleryData,
    } = props;
    const imageVideoRef = useRef<any>();
    const totalImageVideo = () => {
        let total = 0;
        if (imageVideoGalleryData) {
            total += (imageVideoGalleryData.images && Array.isArray(imageVideoGalleryData.images)) ?
            imageVideoGalleryData.images.length: 0;
            total += (imageVideoGalleryData.videos && Array.isArray(imageVideoGalleryData.videos)) ?
            imageVideoGalleryData.videos.length: 0;
            total += (imageVideoGalleryData.pdfs && Array.isArray(imageVideoGalleryData.pdfs)) ?
            imageVideoGalleryData.pdfs.length: 0;
        }
        return total>1;
    };

    return (
        <Modal
            className={classes.modalContainer}
            visible={isVisible}
            onCancel={handleModalClose}
            footer={null}
        >
            <div>
                <div>
                    <Carousel
                        className={classes.carouselContainer}
                        ref={imageVideoRef}
                        dots={false}
                    >
                        {imageVideoGalleryData?.images?.filter((i: any) => i).map((data: any) => (
                            <div style={{ maxWidth: '100%' }}>
                                <div>
                                    <Image.PreviewGroup>
                                        <Image
                                            src={data.url ?? data}
                                        />
                                    </Image.PreviewGroup>
                                    <a
                                        href={data.url ?? data}
                                        target="_blank"
                                        download
                                    >
                                        <DownloadOutlined />
                                    </a>
                                    {
                                        data.source ? (
                                            <div style={{ padding: '10px' }}>Source: {data.source}</div>
                                        ) : (
                                            <></>
                                        )
                                    }
                                </div>
                            </div>
                        ))}
                        {imageVideoGalleryData?.videos?.map((data: any) => (
                            <div style={{ maxWidth: '100%' }}>
                                <video controls style={{ maxWidth: '100%' }}>
                                    <source src={data.url ?? data} type="video/mp4" />
                                    Your browser does not support the video tag.
                                </video>
                                {
                                    data.source ? (
                                        <div style={{ padding: '10px' }}>Source: {data.source}</div>
                                    ) : (
                                        <></>
                                    )
                                }
                            </div>
                        ))}
                        {imageVideoGalleryData?.pdfs?.map((data: any) => (
                            <div key={data.url ?? data} className={classes.pdfContainer}>
                                <PdfRenderer url={data.url ?? data} height={400} isLoading={false} />
                                <a href={data.url ?? data} target="_blank">
                                    <DownloadOutlined />
                                </a>
                            </div>
                        ))}
                    </Carousel>
                </div>
                {totalImageVideo() ? (<div className={classes.imageGalleryContainer}>
                    <LeftCircleOutlined
                        onClick={() => {
                            return (imageVideoRef && imageVideoRef.current) ?
                                imageVideoRef.current.prev() : null;
                        }}
                    >
                        Prev
                    </LeftCircleOutlined>
                    <RightCircleOutlined
                        onClick={() => {
                            return (imageVideoRef && imageVideoRef.current) ?
                                imageVideoRef.current.next() : null;
                        }}
                    >
                        Next
                    </RightCircleOutlined>
                </div>): ''}
            </div>
        </Modal>
    );
};

export default withStyles(styles, { injectTheme: true })(GalleryModal);
