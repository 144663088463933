import * as React from 'react';
import withStyles from 'react-jss';
import * as lodash from 'lodash';

import DetailsCard from './DetailsCard';
import NewPieceDetailsTab from '@components/pages/details/NewPieceDetailsTab';
import ConsignmentLogTab from '@components/pages/details/ConsignmentLogTab';
import ChatComponent from '@components/pages/details/ChatComponent';
import * as moment from 'moment';
import { getColumnsForSupplementaryServices } from '../supplementaryServicesColumns';
import { CssType, ThemeType } from '@theming/jssTypes';
import { SwitchMapListView } from '@components/common/SwitchMapListView';
import { formatDateTime, downloadFileData } from '@utils/utils';
import {
  defaultState,
  CustomActionsBarReducer,
} from '@components/pages/GenericConsignment/CustomActionBar/CustomActionBarReducer';
import { SettingIcon } from '@components/common/icons/SettingsIcon';
import GalleryModal from '@components/common/galleryModal';
import GalleryModalWithSignedUrl from '@components/common/GalleryModalWithSignedUrl';
import CustomColumnsModal from '@components/pages/dashboard/CustomColumnsModal';

import { Link } from 'react-router-dom';
import { Tabs, Table, Image, Tooltip, Button, message } from 'antd';
import { downloadRiderEvents } from '@src/api/dashboard';
import { Modal, Carousel } from 'antd';
import { LeftCircleOutlined, RightCircleOutlined, DownloadOutlined, LoadingOutlined } from '@ant-design/icons';
import { WarningFilled, SoundFilled } from '@ant-design/icons';
import { getRetailTripDetailsRoute } from '@routing/utils';
import { tablePaginationStyle } from '@theming/cssSnippets/commonSnippets';
import { isBoolean, isValidUrl } from '@utils/utils';
import ClaimRequestsTable from '@components/common/ClaimProcessManagement/ClaimRequestsTable';
import TimelineWithStops from '../TimelineWithTabs/TimelineWithStopsModal';
import GenericTabsWithSummaryMetric from '../GenericTabsWithSummaryMetric';
import FixedHeaderTable from '../FixedHeaderTable/FixedHeaderTable';
import { ellipsisWithTooltip } from '../utils';
import { useTranslation } from 'react-i18next';
import { getSignedUrlApi } from '@api/getSignedUrl';
import QcDetails from './QcDetails';
import SupportTicketsTable from 'src/components/pages/SupportTickets/SupportTicketsTable';

interface GalleryModalData {
  images: string[];
  videos: string[];
}

const styles = (theme: ThemeType): CssType => ({
  tabsCardsContainer: {
    backgroundColor: theme.colors.pageBg,
    borderRadius: '4px',
    padding: '0',
    overflow: 'scroll',
    height: '100%',
    flex: 1,
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',
  },
  tabsContainer: {
    boxSizing: 'border-box',
    backgroundColor: theme.colors.pageBg,
    // border: '1px solid rgba(0, 0, 0, 0.06)',
    borderRadius: '8px',
    flex: 'none',
    order: 0,
    alignSelf: 'stretch',
    flexGrow: 0,
    '& .ant-tabs-nav-list': {
      padding: '0.33% 0 0.33% 2%',
    },
    '& .ant-tabs-tab.ant-tabs-tab-active .ant-tabs-tab-btn': {
      color: `${theme.colors.primaryColor} !important`,
    },
    '& .ant-tabs-ink-bar': {
      position: 'absolute',
      background: theme.colors.primaryColor,
    },
    '& .ant-tabs-content': {
      height: '100% !important',
    },
    '& .ant-tabs-content-holder': {
      height: '100%',
    },
    // padding: '5px 10px',
    margin: '1% 0.5%',
    boxShadow: '0 1px 4px 0 rgba(0, 0, 0, 0.25)',
    height: '93%',
    overflowY: 'scroll',
    // borderRadius: '4px'
  },
  borderlessTabContainer: {
    backgroundColor: theme.colors.pageBg,
    flex: 1,
    order: 0,
    alignSelf: 'stretch',
    // flexGrow: 1,
    '& .ant-tabs-nav-list': {
      padding: '0.33% 0 0.33% 2%',
    },
    '& .ant-tabs-tab.ant-tabs-tab-active .ant-tabs-tab-btn': {
      color: `${theme.colors.primaryColor} !important`,
    },
    '& .ant-tabs-ink-bar': {
      position: 'absolute',
      background: theme.colors.primaryColor,
    },
  },
  tableContainer: {
    flex: '1 1 0',
    overflow: 'auto',
    margin: '0 16px 16px 16px',
    boxShadow: '0 1px 4px 0 rgba(0, 0, 0, 0.25)',
    '& tr': {
      verticalAlign: 'top',
    },
    '& th': {
      verticalAlign: 'middle',
    },
    ...tablePaginationStyle(),
    '& .ant-table-tbody > tr > td, .ant-table-thead > tr > th': {
      padding: '16px 8px',
    },
  },
  settingsIcon: {
    display: 'flex',
    color: 'rgba(0, 0, 0, 0.85)',
    justifyContent: 'center',
    alignItems: 'center',
    height: '100%',
    margin: '0 1rem',
  },
  btn: {
    color: 'blue',
    cursor: 'default',
  },
  carouselContainer: {
    padding: '1.5rem',
    maxWidth: '100%',
    '& span': {
      color: `${theme.colors.primaryText} !important`,
    },
    '& span > svg': {
      width: '120%',
      height: '120%',
    },
  },
  buttonBar: {
    display: 'flex',
    justifyContent: 'flex-end',
  },
  setFontSize: {
    fontSize: theme.sizes.heading,
  },
  warningFilledStyle: {
    fontSize: 18,
    color: theme.colors.warning,
    marginLeft: 8,
  },
});

const QC_TABLE_COLUMN = [
  {
    title: 'Parameter',
    dataIndex: 'param',
    key: 'param',
  },
  {
    title: 'Value',
    dataIndex: 'value',
    key: 'value',
  },
];

const PRIMARY_TRIP = 'primary';

const TabSection = (props: any) => {
  const TabPane = Tabs.TabPane;
  const {
    classes,
    data,
    executionStatusList,
    callLogsData,
    reload,
    isMapView,
    handleMapListViewChange,
    metadata,
    currentActiveTab,
    handleCurrentActiveTab,
    handleCustomColumModalSubmit,
    columnList,
    taskColumnList,
    actionList,
    showPickupTaskTab,
    getPropsByHeader = () => {},
    getCustomDetailsCard,
    headerList,
    fullHeadersList,
    isSuperAdmin,
    activeUpdatesList,
    updatesList,
    autoRefreshTrip,
    objectType,
  } = props;
  const activeTab = new URLSearchParams(window.location.search).get(
    'activeTab',
  );

  const { t } = useTranslation();
  let tabs = props.tabs;
  tabs = tabs.filter(tab => tab.tab_header !== 'Deleted Piece Details' || data?.deleted_child_consignments?.length > 0);

  const [visible, setVisible] = React.useState(false);
  const [imageData, setImageData] = React.useState(null);

  const [showCustomColumnModal, setShowCustomColumnModal] =
    React.useState(false);
  const [isImageVideoModalVisible, setIsImageVideoModalVisible] =
    React.useState(false);
  const [isQCTableVisible, setIsQCTableVisible] = React.useState(false);
  const [isGalleryModalVisible, setIsGalleryModalVisible] = React.useState(false);
  const [galleryModalData, setGalleryModalData] =
    React.useState<GalleryModalData>({ images: [], videos: [] });
  const [qcData, setQCData] = React.useState([]);
  const [imageVideoGalleryData, setImageVideoGalleryData] = React.useState({
    images: [],
    videos: [],
  });

  const renderTab = (tab: any) => {
    switch (tab.type) {
      case 'table':
        return tableView(
          tab.tab_header,
          tab?.details[0]?.columns,
          tab.actions_to_show,
          tab.tab_headers_to_show,
        );
      case 'trip_logs_with_timeline':
        return tripLogsWithTimeline(tab.tab_header, tab.details);
      case 'claim_requests':
        return claimRequestsTableView(tab.tab_header, tab.details);
      case 'timeline':
        return timelineView(tab.tab_header, tab.details);
      case 'chat':
          return chatView(tab.tab_header, tab.details);
      case 'support_tickets':
        return supportTicketsTableView(tab?.details?.[0]?.columns);
      default:
        return defaultView(tab.tab_header, tab.details);
    }
  };
  const renderGalleryModal = (imageArray: any[], videoArray: any[]) => {
    if (!imageArray || !videoArray) return t('not available');
    return (
      <a onClick={() => {
        setGalleryModalData({
          images: imageArray,
          videos: videoArray,
        });
        setIsGalleryModalVisible(true);
      }}
      >
        View
      </a>
    );
  };

  const customModalSubmit = (columns, actions, headers, updates) => {
    const newUpdates = {};
    newUpdates[currentActiveTab] = updates;
    handleCustomColumModalSubmit(columns, actions, headers, newUpdates);
  };

  const renderActionsByTabHeader = () => {
    const columnsToShow = columnList;
    const taskColumnsToShow = taskColumnList;

    const defaultMetadata = metadata?.tabs_to_show?.filter((tab) => {
      return tab.tab_header === currentActiveTab;
    });
    const fullColumnList = lodash.get(
      defaultMetadata?.[0],
      'details[0].columns',
      [],
    );
    const fullActionsList = metadata?.actions_to_show;

    const customModal = (
      <div>
        <a className={classes.settingsIcon}>
          {handleCustomColumModalSubmit && (
            <SettingIcon
              className={classes.setFontSize}
              onClick={() => setShowCustomColumnModal(!showCustomColumnModal)}
            />
          )}
        </a>
        {showCustomColumnModal && (
          <CustomColumnsModal
            isVisible={showCustomColumnModal}
            onModalClose={() => setShowCustomColumnModal(false)}
            onModalSubmit={customModalSubmit}
            showFiltersAndActions={true}
            defaultTab="column"
            currentActiveTab={currentActiveTab}
            columnsToShow={
              currentActiveTab === 'Tasks' ? taskColumnsToShow : columnsToShow
            }
            fullColumnList={fullColumnList}
            actionsToShow={actionList}
            fullActionsList={fullActionsList}
            activeUpdatesList={activeUpdatesList}
            hideColumnTab={
              !(
                currentActiveTab === 'Tasks' ||
                currentActiveTab === 'Consignments'
              )
            }
            updatesList={updatesList}
            fullHeadersList={fullHeadersList}
            headersToShow={headerList}
            hideFilters={true}
            showHeaders={isSuperAdmin}
            autoRefreshTrip={autoRefreshTrip}
          />
        )}
      </div>
    );

    switch (currentActiveTab) {
      case 'Consignments':
      case 'Tasks':
        return (
          <div style={{ display: 'flex' }}>
            <SwitchMapListView
              isMapView={isMapView}
              onChange={handleMapListViewChange}
            />
            {customModal}
          </div>
        );
      default:
        return <div style={{ display: 'flex' }}>{customModal}</div>;
    }
  };

  const downloadCNRiderEvents = async () => {
    const body = {
      referenceNumber: data?.reference_number,
    };
    const response: any = await downloadRiderEvents(body);
    if (response.isSuccess) {
      downloadFileData(response.data, 'rider_events', '.xlsx');
    } else {
      message.error(response.errorMessage);
    }
  };

  const generateActionButtons = (actions) => {
    const actionButtons = [];
    if (Array.isArray(actions) && actions.length) {
      actions.forEach((action) => {
        switch (action.key) {
          case 'download_rider_events':
            actionButtons.push(
              <Button onClick={downloadCNRiderEvents}>
                <DownloadOutlined />
              </Button>,
            );
            break;
        }
      });
    }
    return actionButtons;
  };

  const tableView = (tab_header, columns, actions_to_show, tab_headers_to_show) => {
    let tabPane;
    if (tab_header === 'Piece Details') {
      tabPane = (
        <>
          <div className={classes.borderlessTabContainer}>
            <NewPieceDetailsTab
              data={data?.pieces_detail}
              pieceDetails={{
                pieceDetailsData: data?.pieces_detail,
              }}
              columns={columns}
              reload={reload}
              cnReferenceNumber={data?.reference_number}
              bucket={'consignment_piece_details'}
            />
          </div>
        </>
      );
    } else if (tab_header === 'Deleted Piece Details') {
      tabPane = (
        <>
          <div className={classes.borderlessTabContainer}>
            <NewPieceDetailsTab
              data={data?.deleted_child_consignments}
              pieceDetails={{
                pieceDetailsData: data?.deleted_child_consignments,
              }}
              columns={columns}
              reload={reload}
              cnReferenceNumber={data?.reference_number}
              deletedPieces={true}
            />
          </div>
        </>
      );
    } else if (tab_header === 'QC Details') {
      tabPane = (
        <>
          <QcDetails
            referenceNumber={data?.reference_number}
            qcHeaders={tab_headers_to_show}
            qcTableColumns={columns}
          />
        </>
      );
    } else {
      tabPane = (
        <>
          <div className={classes.tabsCardsContainer}>
            <div className={classes.buttonBar}>
              {generateActionButtons(actions_to_show)}
            </div>
            <FixedHeaderTable
              locale={{ emptyText: t('No data found') }}
              dataSource={getDataSourceByTabHeader(tab_header)}
              pagination={false}
              columns={tab_header !== 'Supplementary Services' ? getColumnsForTable(columns): getColumnsForSupplementaryServices(columns)}
              key={tab_header}
              {...getPropsByHeader(tab_header)}
            />
          </div>
        </>
      );
    }

    return tabPane;
  };

  const getDataSourceByTabHeader = (data_header) => {
    switch (data_header) {
      case 'Tracking':
      case 'CN Tracking':
      case 'Consignment Log':
        return data?.events;
      case 'Rider Events':
        return data?.rider_events;
      case 'Job Tracking':
        return data?.job_events;
      case 'Supplementary Services':
        return data?.supplementary_services;
      case 'Item Details':
        return data?.items_detail;
      case 'Call Logs':
        return callLogsData;
      case 'HU Details':
        return data?.hu_details;
      default:
        return data;
    }
  };

  const getDefaultViewDataByHeader = (tab_header) => {
    switch (tab_header) {
      case 'Trip Details':
        return data?.tripDetails;
      default:
        return data;
    }
  };

  const defaultView = (tab_header, details) => {
    return (
      <>
        <div className={classes.tabsCardsContainer}>
          {details?.map((card) => {
            return (
              <DetailsCard
                key={card.header}
                title={card.header}
                data={getDefaultViewDataByHeader(tab_header)}
                columns={card.columns}
                getCustomDetailsCard={getCustomDetailsCard}
                objectType={objectType}
              />
            );
          })}
        </div>
        <div>
          <div style={{ display: 'none' }}>
            <Image.PreviewGroup
              preview={{
                visible,
                onVisibleChange: (vis) => setImage(),
              }}
            >
              {Array.isArray(imageData) ? (
                imageData?.map((url) => <Image src={url} placeholder={true} />)
              ) : (
                <Image src={imageData} placeholder={true} />
              )}
            </Image.PreviewGroup>
          </div>
        </div>
        <div>
          {isQCTableVisible && (
            <Modal
              title={'QC Parameters'}
              className={classes.modalContainer}
              visible={isQCTableVisible}
              onCancel={() => setIsQCTableVisible(false)}
              footer={null}
              style={{ height: 'calc(100vh - 200px)' }}
              width={'400px'}
            >
              <Carousel
                className={classes.carouselContainer}
                prevArrow={<LeftCircleOutlined />}
                nextArrow={<RightCircleOutlined />}
                arrows
              >
                {qcData.map((qcRow, index) => (
                  <div>
                    <div className={classes.tableContainer}>
                      <p style={{ textAlign: 'center' }}>
                        Item {index + 1}
                        <br />
                      </p>
                      <Table
                        locale={{ emptyText: 'No data found' }}
                        dataSource={qcRow}
                        pagination={false}
                        scroll={{ y: 420 }}
                        columns={QC_TABLE_COLUMN}
                      />
                    </div>
                  </div>
                ))}
              </Carousel>
            </Modal>
          )}
        </div>
      </>
    );
  };

  const timelineView = (tab_header, details) => {
    return (
      <>
        <div className={classes.borderlessTabContainer}>
          <ConsignmentLogTab
            events={data?.events}
            data={data}
            details={details}
          />
        </div>
      </>
    );
  };

  const chatView = (tab_header, details) => {
    return (
        <ChatComponent details={details} tripId={data?.tripDetails?.id}/>
    );
  };
  const tripLogsWithTimeline = (tab_header, details) => {
    return (
      <>
        <div className={classes.borderlessTabContainer}>
          <TimelineWithStops
            events={data?.events}
            data={data}
            details={details}
          />
        </div>
      </>
    );
  };
  const claimRequestsTableView = (tab_header, details) => {
    return (
      <ClaimRequestsTable
        consignmentReferenceNumber={data?.reference_number}
      />);
  };

  const supportTicketsTableView = (columns) => {
    return (
      <SupportTicketsTable
        columnsToShow={columns}
        tripReferenceNumber={data?.tripDetails?.trip_reference_number}
        referenceNumber={data?.reference_number}
      />);
  };

  const setImage = (data = null) => {
    setVisible(!visible);
    setImageData(data);
  };

  const renderImage = (data) => {
    if (!data) return t('No Data');
    return (
      <span onClick={() => setImage(data)}>
        <a>{t('View')}</a>
      </span>
    );
  };

  const generateAnchorLink = (data) => {
    const entry = [];
    data?.forEach((link) =>
      entry.push(
        <a href={link} target={'_blank'}>
          {t('Click Here')}<br></br>
        </a>,
      ),
    );
    return entry;
  };

  const displayQCData = (data) => {
    const tableData = [];
    data?.forEach((entry) => {
      const displayData = [];
      entry?.forEach((value) =>
        displayData.push({
          param: value[0],
          value: value[1]?.toString(),
        }),
      );
      tableData.push(displayData);
    });
    setQCData(tableData);
    setIsQCTableVisible(true);
  };

  const generateActionData = (type, data) => {
    if (type.startsWith('QC')) {
      return <a onClick={() => displayQCData(data)}>Click Here</a>;
    }
    switch (type) {
      case 'OTP Generated':
      case 'OTP Verified':
        return isBoolean(data) ? data?.toString() : t('not available');
      case 'Call Recording':
      case 'Image':
        const images = data?.images || [];
        const videos = data?.videos || [];
        if(!images.length && !videos.length) return t('not available');
        return renderGalleryModal(images, videos);
      default:
        return data;
    }
  };

  const toggleImageVideoGallery = (images, videos) => {
    setIsImageVideoModalVisible(!isImageVideoModalVisible);
    setImageVideoGalleryData({
      images,
      videos,
    });
  };

  const renderImageVideoGallery = (imageArray, videoArray) => {
    return (
      <>
        <div
          onClick={() => {
            toggleImageVideoGallery(imageArray, videoArray);
          }}
          className={classes.btn}
        >
          View
        </div>
      </>
    );
  };

  const getColumnsForTable = (columnsToShow) => {
    // const { showThirdParty } = this.props;
    // const columnsToShow = getColumnsToShowCNDetailsTracking(showThirdParty);

    const widthMap = {
      s_no: 50,
      event_string: 120,
      event_time: 140,
    };
    const columns = columnsToShow?.map((col) => {
      return (
        col && {
          dataIndex: col.id,
          title: t(col.pretty_name),
          width: widthMap[col.id] ?? 80,
          render: (x, record, index) => {
            switch (col.key) {
              case 'event_string':
                return (
                  <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                    {ellipsisWithTooltip(x)}
                    {record.is_partial_delivery ? (
                      <Tooltip title={t('Partially Delivered')}>
                        <WarningFilled
                          className={classes.warningFilledStyle}
                        />
                      </Tooltip>
                    ) : record.is_partial_pickedup ? (
                      <Tooltip title={t('Partially Pickedup')}>
                        <WarningFilled
                          className={classes.warningFilledStyle}
                        />
                      </Tooltip>
                    ) : null}
                  </div>
                );
              case 's_no':
                return <div>{index + 1}</div>;
              case 'event_time':
                return x ? moment(x).format('DD/MM/YYYY, HH:mm') : null;
              case 'execution_status':
                return t(getExecutionStatusPrettyNames(x));
              case 'poa_image':
                return x ? renderImage(x) : null;
              case 'updated_by':
                return record.employee_code
                  ? record.employee_code
                  : record.grab_rider_name
                  ? record.grab_rider_name
                  : record.worker_code
                  ? record.worker_code
                  : '';
              case 'employee':
                return record.employee_username
                  ? `${record.employee_username} (${
                      record.employee_code ? record.employee_code : ''
                    })`
                  : '';
              case 'message':
                return isValidUrl(x) ? <a href={x}>{t('Click Here')}</a> : x;
              case 'courier_partner':
                return (
                  <div>
                    <div> {t(x)} </div>
                    {record.courier_account && (
                      <div> {t(record.courier_account)} </div>
                    )}
                  </div>
                );
              case 'geofence_proof_image_list':
                const geofenceImages = record?.geofence_proof_image_list || [];
                const geofenceVideos = record?.geofence_proof_video_list || [];
                if (geofenceImages.length === 0 && geofenceVideos.length === 0) return '';
                return renderGalleryModal(geofenceImages, geofenceVideos);
              case 'event_proof_images':
                const cnImages = record?.event_proof_images || [];
                const cnVideos = record?.event_proof_videos || [];
                if (cnImages.length === 0 && cnVideos.length === 0) return '';
                return (
                  <GalleryModalWithSignedUrl
                    imageArray={cnImages}
                    videoArrya={cnVideos}
                    toFetchSignedUrl={true}
                  />
                );
              case 'job_image_list':
                const jobImages = record?.job_image_list || [];
                const jobVideos = record?.job_video_list || [];
                if (jobImages.length === 0 && jobVideos.length === 0) return '';
                return renderGalleryModal(jobImages, jobVideos);
              case 'pod_image':
              case 'delivery_scan_failure_image':
              case 'pickup_scan_failure_image':
                return renderImage(x);
              case 'arrival_time':
                return formatDateTime(record[col.id]);
              case 'action_details':
                return generateActionData(record.action_type, x);
              case 'trip_id':
                return (
                  <Link
                    to={{
                      pathname: getRetailTripDetailsRoute(x),
                    }}
                    target={'_blank'}
                  >
                    {x}
                  </Link>
                );
              case 'recording_url':
                return x ? (
                  <a
                    href={x}
                    target="_blank"
                    aria-label="URL"
                  >
                    <SoundFilled />
                  </a>
                ) : null;
              case 'otp_generated':
              case 'otp_success':
                if ([
                  'delivered', 'pickup_completed','not_picked_up','attempted',
                  'rto_delivered', 'rvp_delivered',
                ].includes(record.type)) {
                  return x ? t('Yes') : t('No');
                }
                return null;
              case 'geofence_failure_reason':
              case 'geofence_failure_reason_id':
                return x;
              default:
                return t(x);
            }
          },
        }
      );
    });
    return columns.filter((column) => column);
  };

  const getExecutionStatusPrettyNames = (key) => {
    const obj = executionStatusList?.find((lis) => lis.code === key);
    return obj?.name || null;
  };

  const getTabToRender = (tabHeader, tab) => {
    switch (tabHeader) {
      case 'Consignments':
        return showPickupTaskTab ? null : renderTab(tab);
      case 'Pickup Tasks':
        return showPickupTaskTab ? renderTab(tab) : null;
      case 'Drops':
        return data?.data?.tripDetails?.trip_type !== PRIMARY_TRIP
          ? null
          : renderTab(tab);
      default:
        return renderTab(tab);
    }
  };

  return (
    <>
      <GenericTabsWithSummaryMetric
        tabBarExtraContent={renderActionsByTabHeader()}
        defaultActiveKey={activeTab}
        tabs={
          tabs?.map((tab) => {
            return {
              header: {
                title: t(tab.tab_header),
              },
              key: tab.tab_header,
              Component: getTabToRender(tab.tab_header, tab) ?? <></>,
            };
          })
        }
        onChange={(activeKey) => {
          if (handleCurrentActiveTab) {
            handleCurrentActiveTab(activeKey);
            if (activeKey !== 'Consignments') {
              handleMapListViewChange(false);
            }
          }
        }}
      />
      <GalleryModal
        isVisible={isGalleryModalVisible}
        imageVideoGalleryData={galleryModalData}
        handleModalClose={() => { setIsGalleryModalVisible(false); }}
      />
    </>
  );
};

export default withStyles(styles, { injectTheme: true })(TabSection);
