import { getAllTripsData, getSummaryMetricsData } from '../../api/trips';
import {updateUserTripColumnList} from '../../api/genericConsignmentView';

const viewControl = {
  buckets : [
    'retail_all',
    'retail_created',
    'retail_scheduled',
    'retail_assigned',
    'retail_started',
    'retail_completed',
  ],
  fetchData : getAllTripsData,
  fetchSummaryMetrics : getSummaryMetricsData,
  updateUserColumnList: updateUserTripColumnList,
};

const buckets = {
  retail_all : {
    name:'All',
    color:'#7047BC',
    filters : [
      'status',
      'billed_status',
      'verified_status',
      'vehicle_type',
      'vehicleType',
      'route_search',
      'vehicle_id',
      'worker_id',
      'is_hand_delivery_trip',
    ],
    showOnMainDashboardFilters : [
      'worker_id',
      'vehicle_id',
    ],
    actions: ['downloads', 'capture_trip_bill'],
    status_filter : [
      'created',
      'assigned',
      'started',
      'completed',
    ],
    rowSelection : false,
    defaultSortBy: 'last_main_event_time',
  },
  retail_created :{
    name:'Created',
    color:'#7047BC',
    filters : [
      'vehicle_type',
      'vehicleType',
      'route_search',
      'vehicle_id',
      'worker_id',
      'is_hand_delivery_trip',
    ],
    showOnMainDashboardFilters : [
      'worker_id',
      'vehicle_id',
    ],
    actions: [
      'assign',
      'delete',
    ],
    rowSelection : true,
    status_filter : [],
    defaultSortBy: 'last_main_event_time',
  },
  retail_scheduled : {
    name: 'Scheduled',
    color: '#4793BC',
    filters: [
      'vehicle_type',
      'vehicleType',
      'route_search',
      'is_hand_delivery_trip',
      'vehicle_id',
      'worker_id',
    ],
    actions: [
      'assign',
      'delete',
    ],
    showOnMainDashboardFilters : [
      'worker_id',
      'vehicle_id',
    ],
    rowSelection : true,
    status_filter : [],
    defaultSortBy: 'last_main_event_time',
    configKey: 'enable_scheduled_riders',
  },
  retail_assigned : {
    name:'Assigned',
    color:'#7CBE28',
    filters : [
      'vehicle_type',
      'vehicleType',
      'route_search',
      'vehicle_id',
      'worker_id',
      'is_hand_delivery_trip',
    ],
    showOnMainDashboardFilters : [
      'worker_id',
      'vehicle_id',
    ],
    actions: [
      'delete',
    ],
    rowSelection : true,
    status_filter : [],
    defaultSortBy: 'last_main_event_time',
  },
  retail_started : {
    name:'Started',
    color:'#4782BC',
    filters : [
      'vehicle_type',
      'vehicleType',
      'route_search',
      'vehicle_id',
      'worker_id',
      'is_hand_delivery_trip',
      'unassigned_workers_only',
    ],
    showOnMainDashboardFilters : [
      'worker_id',
      'vehicle_id',
    ],
    actions: [
    ],
    rowSelection : false,
    status_filter : [],
    defaultSortBy: 'last_main_event_time',
  },
  retail_completed : {
    name:'Completed',
    color:'#47BC9C',
    filters : [
      'billed_status',
      'verified_status',
      'vehicle_type',
      'vehicleType',
      'route_search',
      'settlement',
      'vehicle_id',
      'worker_id',
      'is_hand_delivery_trip',
    ],
    showOnMainDashboardFilters : [
      'worker_id',
      'vehicle_id',
    ],
    actions: ['capture_trip_bill'],
    rowSelection : false,
    status_filter : [],
    defaultSortBy: 'last_main_event_time',
  },
};
const bucketColor = {
  retail_all : '#7047BC',
  retail_created : '#7047BC',
  retail_scheduled: '#4793BC',
  retail_assigned : '#7CBE28',
  retail_started : '#4782BC',
  retail_completed : '#47BC9C',
};

export default { viewControl, bucketColor, buckets };
